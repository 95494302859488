// extracted by mini-css-extract-plugin
export const filterBrown = "c_j";
export const collapseButton = "c_k";
export const collapsed = "c_l";
export const collapsedContent = "c_m";
export const collapseContainer = "c_n";
export const mediaContainer = "c_p";
export const imgOverlay = "c_q";
export const modalImage = "c_r";
export const imageContainer = "c_s";
export const mainOverlay = "c_t";
export const order2 = "c_v";
export const alignSelfStart = "c_w";
export const alignSelfCenter = "c_x";
export const place = "c_y";
export const firstImageContainer = "c_z";