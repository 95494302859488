import React from 'react';

const ArrowDown = (props) => (
  <svg
    version='1.1'
    id='Layer_1'
    x='25px'
    y='25px'
    viewBox='0 0 200 250'
    style={{ width: 25, ...props.style }}
  >
    <g>
      <g>
        <path
          style={{ fill: 'var(--element3)' }}
          d='M169.304,89.151c-6.801-1.759-15.093-0.611-22.069-0.499c-8.445,0.136-17.041,0.607-25.335,2.364
			c-0.006-0.009,0.001-0.018-0.006-0.027c-0.072-0.095-0.156-0.178-0.229-0.273c-0.862-12.003-1.052-24.009-1.098-36.016
			c0.025-0.085,0.016-0.163-0.001-0.255c-0.062-17.233,0.171-34.467-0.811-51.693c-0.013-0.228-0.146-0.352-0.24-0.514
			c-0.166-0.729-0.711-1.342-1.687-1.308c-15.908,0.561-31.81-0.561-47.713-0.202c-0.561-1.095-2.458-0.922-2.577,0.299
			c-0.772,0.517-0.704,1.667,0.174,2.084c2.521,21.509-0.718,43.315-0.364,64.847c-0.181,0.09-0.262,0.38-0.043,0.538
			c0.018,0.013,0.034,0.027,0.052,0.04c0.158,8.102,0.822,16.165,2.528,24.156c-0.106-0.027-0.148-0.114-0.268-0.124
			c-0.167-0.014-0.339-0.037-0.508-0.053c-0.005-0.004-0.009-0.009-0.014-0.014c-0.036-0.034-0.068,0.013-0.108,0.001
			c-14.305-1.35-31.629-7.348-45.652-2.788c-0.626,0.203-0.826,0.947-0.379,1.433c0.064,0.069,0.128,0.139,0.191,0.208
			c0.111,0.121,0.268,0.18,0.43,0.194c-0.169,0.239-0.258,0.531-0.062,0.879c19.836,35.174,45.991,69.935,72.951,100.067
			c0.857,0.957,2.007,0.529,2.555-0.308c21.275-14.171,32.078-37.139,45.951-57.792c8.649-12.879,19.503-27.643,25.256-42.054
			c0.156-0.391,0.052-0.716-0.133-0.998C170.57,90.592,170.311,89.412,169.304,89.151z M73.588,33.961
			c0.055-2.605,0.063-5.202,0.006-7.787c11.532,15.4,29.574,25.526,43.216,38.997c0.082,2.77,0.108,5.543,0.243,8.309
			C105.002,58.471,87.967,46.73,73.588,33.961z M117.143,75.598c0.04,0.746,0.107,1.49,0.151,2.236
			C105.78,63.436,87.366,53.633,73.528,42.018c-0.085-0.071-0.177-0.08-0.267-0.086c0.112-2.253,0.248-4.511,0.308-6.755
			C87.797,48.889,102.446,62.434,117.143,75.598z M100.771,48.793c-9.569-7.303-19.808-14.282-27.201-23.94
			c-0.012-0.015-0.03-0.012-0.042-0.025c-0.055-1.794-0.143-3.583-0.268-5.364c12.415,15.054,27.698,27.768,43.43,39.243
			c0.021,1.291,0.014,2.583,0.045,3.873C112.692,57.041,106.011,52.793,100.771,48.793z M116.647,52.614
			c0.01,1.321-0.001,2.642,0.017,3.963c-15.374-11.834-30.335-23.966-43.511-38.26c-0.172-2.157-0.433-4.299-0.732-6.433
			C84.653,27.596,101.353,39.909,116.647,52.614z M72.339,11.165c-0.301-2.036-0.728-4.049-1.175-6.058
			c9.426,8.738,19.686,16.506,28.894,25.491c4.233,4.132,8.854,8.077,13.4,11.864c1.082,0.901,1.994,2.401,3.213,3.228
			c-0.002,1.511-0.041,3.022-0.034,4.532C102.699,36.358,85.138,26.405,72.339,11.165z M73.216,42.914
			c14.36,13.012,30.426,23.614,44.238,37.267c0.131,1.996,0.239,3.994,0.406,5.987c-13.102-14.995-30.44-25.766-44.812-39.541
			C73.122,45.387,73.152,44.154,73.216,42.914z M117.298,4.581c-0.041,1.792-0.062,3.592-0.09,5.389
			c-2.677-2.214-6.014-3.821-9.217-5.292C111.094,4.692,114.196,4.676,117.298,4.581z M105.281,4.649
			c0.015,0.009,0.012,0.026,0.028,0.035c4.141,2.17,7.907,4.723,11.868,7.147c-0.016,1.009-0.033,2.016-0.051,3.024
			c-1.055-0.453-2.327-1.34-2.835-1.63c-2.028-1.156-3.977-2.459-5.921-3.752c-2.367-1.575-4.68-3.228-7.004-4.865
			C102.671,4.631,103.977,4.634,105.281,4.649z M91.041,4.333c0.023,0.03-0.016,0.073,0.027,0.095
			c4.798,2.439,9.18,5.647,13.515,8.815c3.694,2.7,7.399,6.269,11.661,8.034c0.507,0.21,0.959-0.586,0.539-0.926
			c-7.334-5.942-15.98-11.982-24.604-15.971c2.343,0.085,4.686,0.118,7.029,0.173c3.854,2.709,7.628,5.531,11.517,8.192
			c1.164,0.797,4.104,3.316,6.376,3.748c-0.065,4.085-0.149,8.162-0.204,12.249c-8.65-9.807-21.254-18.616-32.628-24.684
			C86.526,4.159,88.783,4.248,91.041,4.333z M83.922,4.044c-0.14,0.087-0.221,0.253-0.035,0.358
			c12.493,7.072,21.991,17.145,32.799,26.332c0.056,0.047,0.117,0.027,0.176,0.056c-0.025,2.089-0.086,4.172-0.104,6.262
			c-2.283-0.964-5.371-4.139-6.673-5.165c-4.302-3.392-8.501-6.915-12.682-10.456c-6.676-5.653-13.406-11.329-19.704-17.405
			c-0.218-0.211-0.547,0.117-0.333,0.332c7.386,7.448,15.35,14.38,23.236,21.287c3.811,3.339,7.648,6.673,11.673,9.755
			c1.129,0.864,2.82,2.513,4.467,3.049c-0.013,1.656-0.048,3.311-0.054,4.966c-1.306-1.982-4.524-4.086-5.867-5.228
			c-4.193-3.564-8.361-7.252-12.333-11.057c-8.629-8.267-18.611-14.97-27.503-22.948c-0.057-0.244-0.083-0.493-0.143-0.736
			C75.201,3.644,79.561,3.85,83.922,4.044z M98.708,189.854c-26.927-30.294-50.807-64.896-73.655-98.321
			c-0.127-0.185-0.298-0.255-0.467-0.314c0.396-0.167,0.792-0.334,1.188-0.501c0.063-0.017,0.065-0.079,0.082-0.13
			c0.512,1.135,1.458,1.47,2.812,1.051c13.607,16.945,24.299,36.012,38.206,52.702c11.769,14.123,27.476,24.824,36.028,41.5
			c0.016,0.031,0.051,0.03,0.07,0.057C101.56,187.226,100.192,188.594,98.708,189.854z M103.887,185.048
			c-5.833-13.833-18.411-24.132-28.74-34.574c-17.496-17.688-29.638-39.79-45.318-59.023c1.798,0.017,3.594,0.086,5.385,0.246
			c0.248,0.016,0.493,0.068,0.742,0.088c18.755,33.381,48.075,59.425,72.41,88.638C106.894,181.984,105.436,183.56,103.887,185.048z
			 M109.205,179.553c-22.791-30.438-51.74-55.396-72.209-87.646c1.851,0.178,3.695,0.439,5.535,0.717
			c7.564,15.453,19.921,27.658,31.875,39.808c13.48,13.7,26.323,28.062,38.347,43.059
			C111.583,176.854,110.426,178.233,109.205,179.553z M113.709,174.395c-21.792-27.632-53.15-50.849-70.115-81.622
			c1.656,0.262,3.307,0.554,4.955,0.845c20.285,27.8,46.188,50.806,67.721,77.595C115.418,172.276,114.585,173.354,113.709,174.395z
			 M117.167,170.1c-20.816-26.8-46.05-49.702-67.189-76.234c1.41,0.254,2.835,0.521,4.264,0.778
			c-0.123,0.123-0.16,0.316,0.014,0.468c23.892,20.74,38.229,54.76,66.134,70.605C119.307,167.171,118.287,168.678,117.167,170.1z
			 M121.167,164.656c-26.41-19.205-40.887-49.558-66.214-69.885c2.592,0.457,5.207,0.844,7.821,1.032
			c20.684,21.401,39.432,44.72,60.743,65.491C122.727,162.409,121.976,163.556,121.167,164.656z M124.391,160.042
			c-20.094-21.369-39.118-43.962-60.377-64.188c1.874,0.083,3.746,0.082,5.605-0.143c0.487-0.059,0.816-0.281,1.052-0.559
			c9.759,11.024,18.94,22.546,28.594,33.663c8.148,9.383,17.328,20.175,28.601,26.081
			C126.711,156.614,125.577,158.348,124.391,160.042z M128.812,153.49c-22.614-16.492-37.874-39.75-57.783-59.063
			c0.034-0.216,0.03-0.438-0.02-0.654c0.634,0.023,1.286-0.331,1.202-1.073c-0.203-1.799-0.358-3.607-0.474-5.422
			c17.848,22.243,38.534,42.554,59.293,62.021c0.116,0.109,0.242,0.121,0.365,0.14C130.525,150.784,129.698,152.155,128.812,153.49z
			 M132.016,148.464c-0.024-0.035-0.01-0.073-0.042-0.108c-19.925-21.106-41.138-40.765-60.305-62.601
			c-0.128-2.466-0.167-4.948-0.171-7.437c19.787,23.053,44.299,41.441,63.961,64.678
			C134.311,144.819,133.183,146.651,132.016,148.464z M136.323,141.625c-18.714-24.272-44.287-42.098-64.827-64.721
			c0.01-1.695,0.048-3.395,0.1-5.097c14.343,11.363,26.074,25.38,39.317,37.998c7.255,6.911,15.464,12.71,22.773,19.569
			c1.17,1.099,2.231,2.36,3.328,3.528c0.944,1.008,1.884,2.022,2.817,3.042C138.656,137.838,137.511,139.731,136.323,141.625z
			 M141.101,133.889c-5.931-10.199-20.742-18.264-28.835-25.438c-13.92-12.34-25.455-26.901-40.631-37.69
			c0.091-2.638,0.229-5.284,0.382-7.934c18.118,27.224,46.06,47.046,72.129,66.13C143.127,130.602,142.118,132.243,141.101,133.889z
			 M146.92,124.463c-0.618,1.019-1.261,2.041-1.891,3.062c-25.705-19.984-53.86-39.058-72.88-65.985
			c-0.016-0.022-0.04-0.019-0.058-0.037c0.091-1.512,0.193-3.026,0.292-4.54c14.177,14.425,29.297,27.879,44.386,41.335
			c9.09,8.107,19.034,20.862,30.252,25.875c0.022,0.012,0.04-0.003,0.062,0.005C147.032,124.27,146.975,124.375,146.92,124.463z
			 M147.674,123.159c-0.016-0.014-0.013-0.034-0.031-0.047c-11.973-8.675-22.545-20.062-33.688-29.802
			c-13.876-12.127-27.521-24.508-41.281-36.765c-0.089-0.079-0.179-0.031-0.262,0.007c0.191-2.903,0.382-5.805,0.563-8.707
			c13.628,14.962,31.252,25.69,45.069,40.556c0.086,0.953,0.143,1.908,0.237,2.86c0.048,0.486,0.271,0.85,0.563,1.121
			c-0.184,0.78,0.195,1.722,1.145,1.608c0.871-0.105,1.742-0.173,2.613-0.266c9.134,7.712,18.213,16.561,28.622,22.458
			C149.957,118.791,148.769,121.207,147.674,123.159z M151.714,115.176c-5.861-4.033-11.499-8.308-17-12.848
			c-3.55-2.93-7.157-6.155-11.082-8.707c2.154-0.215,4.311-0.324,6.465-0.476c6.647,7.546,14.511,13.964,22.737,19.725
			C152.457,113.646,152.079,114.423,151.714,115.176z M153.37,111.775c-7.782-5.938-15.554-11.749-22.491-18.688
			c2.379-0.16,4.76-0.288,7.143-0.4c4.979,5.667,11.463,10.06,17.928,13.993C155.061,108.372,154.199,110.087,153.37,111.775z
			 M156.599,105.466c-5.931-4.211-12.301-7.594-17.448-12.839c1.946-0.085,3.896-0.131,5.848-0.178
			c2.296,2.395,4.618,4.751,7.159,6.893c1.663,1.402,3.59,2.99,5.676,3.938C157.419,104.006,157,104.719,156.599,105.466z
			 M158.665,101.806c-2.144-1.312-4.34-2.495-6.358-4.095c-2.09-1.655-4.049-3.471-5.987-5.303c0.305-0.006,0.609-0.023,0.914-0.028
			c1.856-0.03,3.806,0.046,5.779,0.138c2.319,2.258,4.894,4.451,7.596,6.217C159.95,99.718,159.298,100.737,158.665,101.806z
			 M161.639,97.273c-2.14-1.595-4.365-3.083-6.531-4.659c3.608,0.172,7.269,0.309,10.724-0.087
			C164.38,93.774,162.987,95.418,161.639,97.273z'
        />
      </g>
    </g>
  </svg>
);

export default ArrowDown;
