import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ArrowDown from '../components/arrowdown';
import { Media } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Text } from '../components/text';
import '../components/css/media.css';
import * as trainStyles from '../components/css/training.module.css';
import * as layoutStyles from '../components/css/layout.module.css';

const Training = (props) => {
  const expand = (e) => {
    e.target.classList.toggle('collapsed');
    e.target.nextSibling.classList.toggle('collapsed');
  };

  const imageClick = (e) => {
    const root = document.querySelector('.rootContainer');
    root.classList.toggle('rblr');

    const overlay = document.createElement('div');
    overlay.classList.add('imgOverlay');
    document.documentElement.style.overflow = 'hidden';

    const image = document
      .querySelectorAll('.imageContainer')
      [e.target.id.split('-')[1]].cloneNode(true);
    image.childNodes[1].style.background = 'transparent';
    overlay.prepend(image);
    image.classList.add('image');
    image.style.display = 'block';

    const closeOverlay = () => {
      overlay.remove();
      root.classList.toggle('rblr');
      document.documentElement.style.overflow = null;
    };
    overlay.addEventListener('click', closeOverlay);
    root.insertAdjacentElement('beforebegin', overlay);
  };

  return (
    <motion.div
      key={props.location.pathname}
      variants={container}
      initial='hidden'
      animate='show'
      exit='exit'
      className={`${layoutStyles.textContainer} ${layoutStyles.subContainerL}`}
    >
      <motion.div key='react' variants={item}>
        <Media className={trainStyles.mediaContainer}>
          <StaticImage
            id='image-0'
            onClick={imageClick}
            src='../images/diploma/frontend.png'
            alt='coursera diploma'
            className={trainStyles.firstImageContainer}
            height={200}
            quality={100}
            style={{ filter: 'var(--grayscale)', cursor: 'pointer' }}
          />

          <Media.Body className='lt'>
            <Text tid='react_title' />
            <div className={trainStyles.place}>
              Hong Kong University of Science and Technology @ Coursera - 11/20
              - 01/21
            </div>
            <div className={trainStyles.collapseContainer}>
              <button className={`collapseButton collapsed`} onClick={expand}>
                <ArrowDown style={{ marginRight: 10 }} />
                <Text tid='syllabus' />
                <ArrowDown style={{ marginLeft: 10 }} />
              </button>
              <div className={`collapsedContent collapsed`}>
                <Text tid='react_syllabus' />
              </div>
            </div>
          </Media.Body>
        </Media>
      </motion.div>

      <motion.div key='node' variants={oddItem}>
        <Media className={trainStyles.mediaContainer}>
          <StaticImage
            id='image-1'
            src='../images/diploma/backend.png'
            alt='coursera diploma'
            onClick={imageClick}
            className={`${trainStyles.firstImageContainer} ${trainStyles.order2}`}
            height={200}
            quality={100}
            style={{ filter: 'var(--grayscale)', cursor: 'pointer' }}
          />

          <Media.Body className='rt'>
            <Text tid='node_title' />
            <div className={trainStyles.place}>
              Hong Kong University of Science and Technology @ Coursera - 01/21
              - 02/21
            </div>
            <div className={trainStyles.collapseContainer}>
              <button className={`collapseButton collapsed`} onClick={expand}>
                <ArrowDown style={{ marginRight: 10 }} />
                <Text tid='syllabus' />
                <ArrowDown style={{ marginLeft: 10 }} />
              </button>
              <div className={`collapsedContent collapsed`}>
                <Text tid='node_syllabus' />
              </div>
            </div>
          </Media.Body>
        </Media>
      </motion.div>

      <motion.div key='upc' variants={item}>
        <Media key='upc' className={trainStyles.mediaContainer}>
          <StaticImage
            id='image-2'
            src='../images/diploma/certprof.png'
            alt='cp diploma'
            onClick={imageClick}
            className={trainStyles.firstImageContainer}
            height={200}
            quality={100}
            style={{ filter: 'var(--grayscale)', cursor: 'pointer' }}
          />

          <Media.Body className='lt'>
            <Text tid='upc_title' />
            <div className={trainStyles.place}>
              Universitat Politècnica de Catalunya - 11/19 - 11/20
            </div>
            <div className={trainStyles.collapseContainer}>
              <button className={`collapseButton collapsed`} onClick={expand}>
                <ArrowDown style={{ marginRight: 10 }} />
                <Text tid='syllabus' />
                <ArrowDown style={{ marginLeft: 10 }} />
              </button>
              <div className={`collapsedContent collapsed`}>
                <Text tid='upc_syllabus' />
              </div>
            </div>
          </Media.Body>
        </Media>
      </motion.div>
      <StaticImage
        id='oldip'
        key='pic12'
        src='../images/diploma/frontend.png'
        alt='coursera diploma'
        className={`${trainStyles.imageContainer} imageContainer`}
        loading='eager'
        imgClassName={trainStyles.modalImage}
        objectFit='contain'
        width={1200}
        quality={100}
        style={{
          filter: 'var(--grayscale)',
          display: 'none',
          cursor: 'pointer',
        }}
      />

      <StaticImage
        key='pic22'
        src='../images/diploma/backend.png'
        alt='coursera diploma'
        className={`${trainStyles.imageContainer} imageContainer`}
        imgClassName={trainStyles.modalImage}
        loading='eager'
        objectFit='contain'
        quality={100}
        style={{
          filter: 'var(--grayscale)',
          display: 'none',
          cursor: 'pointer',
        }}
      />

      <StaticImage
        key='pic32'
        src='../images/diploma/certprof.png'
        alt='cp diploma'
        className={`${trainStyles.imageContainer} imageContainer`}
        imgClassName={trainStyles.modalImage}
        loading='eager'
        objectFit='contain'
        quality={100}
        style={{
          filter: 'var(--grayscale)',
          display: 'none',
          cursor: 'pointer',
        }}
      />
    </motion.div>
  );
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
    },
  },
  exit: {
    opacity: 0,
    x: 1000,
    transition: { duration: 0.3 },
  },
};
const item = {
  hidden: { x: -1600 },
  show: {
    x: 0,
    transition: { duration: 0.3 },
  },
};

const oddItem = {
  hidden: { x: 1600 },
  show: {
    x: 0,
    transition: { duration: 0.3 },
  },
};

export default Training;
